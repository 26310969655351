import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { MatchesProvider } from "./context/MatchesContext";
import App from "./App";
import Rules from "./routes/rules";
import SignIn from "./routes/signin";
import Register from "./routes/register";
import Matches from "./routes/matches";
import Pools from "./routes/pools";
import UserProfile from "./routes/user-profile";
import SubpoolRankings from './routes/SubPoolRankings';
import Groups from './routes/groups';
import Stats from './routes/Stats';

//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthProvider>
    <MatchesProvider>
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<App />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="matches" element={<Matches />} />
          <Route path="pools" element={<Pools />} />
          <Route path="groups" element={<Groups />} />
          <Route path="user">
            <Route path=":id" element={<UserProfile />} />
          </Route>
          <Route path="/subpool/:subpoolName" element={<SubpoolRankings />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/register" element={<Register/>} />
          <Route path="/stats" element={<Stats/>} />
        </Routes>
      </BrowserRouter>
    </MatchesProvider>
  </AuthProvider>
);

// reportWebVitals();