import { useEffect, useState } from 'react';
import { db } from "../config/firebase";
import { ref, get } from 'firebase/database';
import Navbar from "../components/Navbar";
import { DefaultContainer } from '../components/Containers';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const Stats = () => {
  const [winnerBreakdown, setWinnerBreakdown] = useState<{ [key: string]: number }>({});
  const [topScorerBreakdown, setTopScorerBreakdown] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const fetchData = async () => {
      const predictionsRef = ref(db, 'predictions');
      const predictionsSnapshot = await get(predictionsRef);

      if (predictionsSnapshot.exists()) {
        const predictionsData = predictionsSnapshot.val();

        let winnerCount: { [key: string]: number } = {};
        let topScorerCount: { [key: string]: number } = {};
        let totalPredictions = 0;

        for (let key in predictionsData) {
          const prediction = predictionsData[key];
          if (prediction.winner) {
            winnerCount[prediction.winner] = (winnerCount[prediction.winner] || 0) + 1;
          }
          if (prediction.topScorer) {
            topScorerCount[prediction.topScorer] = (topScorerCount[prediction.topScorer] || 0) + 1;
          }
          totalPredictions += 1;
        }

        for (let key in winnerCount) {
          winnerCount[key] = (winnerCount[key] / totalPredictions) * 100;
        }

        for (let key in topScorerCount) {
          topScorerCount[key] = (topScorerCount[key] / totalPredictions) * 100;
        }

        setWinnerBreakdown(winnerCount);
        setTopScorerBreakdown(topScorerCount);
      }
    };

    fetchData();
  }, []);

  const shadesOfBlue = [
 '#003f5c',
    '#2f4b7c',
    '#665191',
    '#a05195',
    '#d45087',
    '#f95d6a',
    '#ff7c43',
    '#ffa600',
    '#1f77b4',
    '#aec7e8'
  ];

  const prepareTop10ChartData = (data: { [key: string]: number }, maxItems = 10) => {
    const sortedData = Object.entries(data)
      .sort(([, a], [, b]) => b - a)
      .slice(0, maxItems);
    const labels = sortedData.map(([key]) => key);
    const values = sortedData.map(([, value]) => value);

    return {
      labels: labels,
      datasets: [{
        data: values,
        backgroundColor: shadesOfBlue.slice(0, values.length) // Use different shades of blue
      }]
    };
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <Navbar />
      <DefaultContainer className="prediction-breakdown-container">
        <h2 className="text-2xl my-4 font-bold" style={{ color: '#2c3e50' }}>Statistieken</h2>
        <div className="py-4 px-8 bg-white shadow-lg rounded-lg my-2">
          <h3 className="text-xl font-bold mb-4" style={{ color: '#2c3e50' }}>Top {isMobile ? 5 : 10} Voorspellingen Winnaar</h3>
          <div style={{ margin: '20px 0' }}>
            <Bar
              data={prepareTop10ChartData(winnerBreakdown, isMobile ? 5 : 10)}
              options={{
                indexAxis: 'y',
                scales: {
                  x: {
                    beginAtZero: true,
                    ticks: {
                      callback: (value) => `${value}%`
                    }
                  }
                },
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
            />
          </div>
        </div>
        <div className="py-4 px-8 bg-white shadow-lg rounded-lg my-2">
          <h3 className="text-xl font-bold mb-4" style={{ color: '#2c3e50' }}>Top {isMobile ? 5 : 10} Voorspellingen Topscorer</h3>
          <div style={{ margin: '20px 0' }}>
            <Bar
              data={prepareTop10ChartData(topScorerBreakdown, isMobile ? 5 : 10)}
              options={{
                indexAxis: 'y',
                scales: {
                  x: {
                    beginAtZero: true,
                    ticks: {
                      callback: (value) => `${value}%`
                    }
                  }
                },
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
            />
          </div>
        </div>
      </DefaultContainer>
    </>
  );
};

export default Stats;
