import { useEffect, useState } from 'react';
import { ref, update, push, set, get } from 'firebase/database';
import { db } from '../config/firebase';
import Select from 'react-select';
import { useAuth } from "../context/AuthContext";
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import { DefaultContainer } from "../components/Containers";
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Define the OptionType interface for the select options
interface OptionType {
  value: string;
  label: string;
}

interface PoolOption {
  value: string;
  label: string;
}

type SubpoolCreationResult = {
  newSubpoolId: string | null;
  joinCode: string;
};

interface Subpool {
  id: string;
  name: string;
  memberCount: number;
}

interface MessageState {
  text: string;
  type: "error" | "success";
  link: string | null; // Now can be either a string or null
}


const generateJoinCode = () => {
  return Math.random().toString(36).substring(2, 14).toUpperCase();
};

const Pools = () => {

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const authUser = useAuth()?.user;
  const userId = authUser?.uid;
  const [subpoolName, setSubpoolName] = useState('');
  const [joinCode, setJoinCode] = useState("");
  const [selectedPool, setSelectedPool] = useState<OptionType | null>(null);
  const [message, setMessage] = useState<MessageState>({
    text: "",
    type: "error",
    link: null // Starting as null, but can be set to a string later
  });
  const [, setSubpoolCreationResult] = useState<SubpoolCreationResult | null>(null);
  const [poolsOptions, setPoolsOptions] = useState<PoolOption[]>([]);
  const navigate = useNavigate(); // Instantiate navigate
  const [subpools, setSubpools] = useState<Subpool[]>([]);

  useEffect(() => {
    const subpoolsRef = ref(db, 'subpools');
    get(subpoolsRef).then(snapshot => {
      if (snapshot.exists()) {
        const subpoolDetails = Object.keys(snapshot.val()).map(key => {
          const subpool = snapshot.val()[key];
          return {
            id: key,
            name: subpool.name,
            memberCount: subpool.members ? Object.keys(subpool.members).length : 0 // Safely handle possibly undefined members
          };
        });

        setSubpools(subpoolDetails);
      } else {
        console.log("No subpools available.");
      }
    }).catch(error => {
      console.error("Error fetching subpools:", error);
    });
  }, []);


  useEffect(() => {
    // Fetch all pools when component mounts
    const fetchSubpools = async () => {
      const snapshot = await get(ref(db, 'subpools'));
      if (snapshot.exists()) {
        const loadedOptions: OptionType[] = Object.keys(snapshot.val()).map(key => ({
          value: key,
          label: snapshot.val()[key].name || 'Unnamed Pool'
        }));
        setPoolsOptions(loadedOptions);

        // Set selected pool based on URL parameter
        if (queryParams.name) {
          const foundPool = loadedOptions.find(option => option.label === queryParams.name);
          if (foundPool) {
            setSelectedPool(foundPool);
          }
        }
      }
    };
    fetchSubpools();
  }, [location.search]);  // Dependency on location.search ensures it runs when URL changes


  useEffect(() => {
    if (queryParams.name && queryParams.code) {
      setJoinCode(queryParams.code.toString());
    }
  }, [queryParams.name, queryParams.code]);

  // useEffect to trigger join after joinCode has been set
  useEffect(() => {
    if (joinCode) {
      handleJoinSubpool();
    }
  }, [joinCode]);


  const handleJoinSubpool = async () => {
    if (!joinCode.trim()) {
      setMessage({ text: "Vul een join code in.", type: "error", link: null });
      return;
    }


    const subpoolsRef = ref(db, 'subpools');
    try {
      let subpoolId = null;
      // Query all subpools to find the one with the matching join code
      const snapshot = await get(subpoolsRef);
      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot: { val: () => any; key: any; }) => {
          const data = childSnapshot.val();
          if (data.joinCode === joinCode) {
            subpoolId = childSnapshot.key; // Save the matching subpool's ID
            return true; // Stop the loop once a match is found
          }
        });
      }

      if (subpoolId) {
        await joinSubpool(subpoolId);
      } else {
        setMessage({ text: "Geen subpool gevonden met de opgegeven code.", type: "error", link: null });
      }
    } catch (error) {
      console.error("Error searching for subpool:", error);
      setMessage({ text: "Er is een fout opgetreden bij het zoeken naar de subpool.", type: "error", link: null });
    }
  };


  const createSubpool = async (subpoolName: string, userId: string) => {
    try {
      if (!subpoolName || !userId) throw new Error("Invalid subpool name or user ID");

      // Generate a new subpool reference with a unique key
      const newSubpoolRef = push(ref(db, 'subpools'));
      const newSubpoolId = newSubpoolRef.key || "";
      const joinCode = generateJoinCode(); // Generate join code


      // Prepare subpool data
      const subpoolData = {
        id: newSubpoolId,
        name: subpoolName,
        ownerId: userId,
        joinCode: joinCode, // Store join code
        members: {
          [userId]: true // Automatically add the creator as a member
        }
      };

      // Set the data at the new subpool's reference
      await set(newSubpoolRef, subpoolData);
      joinSubpoolMultiple(newSubpoolId, userId); // Automatically add the creator as a member

      return { newSubpoolId, joinCode }; // Return the newly created subpool ID and joinCode
    } catch (error) {
      setMessage({ text: "Er ging iets fout bij het aanmaken van een subpool.", type: "error", link: null });
      throw error; // Rethrow the error for further handling
    }
  };


  const joinSubpool = async (subpoolId: string) => {
    if (!authUser || !authUser.uid) {
      setMessage({ text: "Log in om deel te nemen aan een subpool.", type: "error", link: null });
      return;
    }

    const subpoolRef = ref(db, `subpools/${subpoolId}/members`);
    const subpoolDetailsRef = ref(db, `subpools/${subpoolId}`);

    try {
      const updates = { [authUser.uid]: true };
      await update(subpoolRef, updates); // Update members list

      // Fetch the subpool details to get the name
      const subpoolSnapshot = await get(subpoolDetailsRef);
      if (subpoolSnapshot.exists()) {
        const subpoolData = subpoolSnapshot.val();
        joinSubpoolMultiple(subpoolId, authUser.uid); // Additional business logic as needed

        setMessage({ text: "Succesvol aangemeld bij de subpool!", type: "success", link: null });
        navigate(`/subpool/${subpoolData.name}`); // Redirect using the subpool name
      } else {
        setMessage({ text: "Subpool niet gevonden.", type: "error", link: null });
      }
    } catch (error) {
      console.error("Error joining subpool:", error);
      setMessage({ text: "Er ging iets mis bij het deelnemen aan de subpool.", type: "error", link: null });
    }
  };

  const joinSubpoolMultiple = async (subpoolId: string, userId: string) => {
    try {
      if (!subpoolId || !userId) throw new Error("Invalid subpool ID or user ID");

      // Add the subpool ID to the user's list of subpools
      const userSubpoolsRef = ref(db, `users/${userId}/subpools/${subpoolId}`);
      await set(userSubpoolsRef, true);

      return "Joined subpool successfully";
    } catch (error) {
      console.error("Error joining subpool:", error);
      throw error; // Rethrow the error for further handling
    }
  };


  // Handle change on select
  const handleChange = (selectedOption: OptionType | null) => {
    setSelectedPool(selectedOption);
    console.log("Selected pool:", selectedOption?.label);
  };

  const handleCreateSubpool = async () => {
    setMessage({ text: "", type: "error", link: null }); // Clear previous message
    if (!subpoolName.trim()) {
      setMessage({ text: "Vul een (geldige) naam in.", type: "error", link: null });
      return;
    }
    if (!userId) {
      setMessage({ text: "Login om deze functionaliteit te gebruiken", type: "error", link: null });
      return;
    }
    try {
      const result = await createSubpool(subpoolName, userId);
      if (result) {
        setSubpoolCreationResult(result);
        const linkUrl = `https://ekpool.pro/pools?name=${encodeURIComponent(subpoolName)}&code=${result.joinCode}`;
        setMessage({
          text: "Subpool succesvol aangemaakt! Deel deze link: ",
          type: "success",
          link: linkUrl
        });
      }
    } catch (error) {
      setMessage({ text: "Er ging iets fout bij het aanmaken van een subpool.", type: "error", link: null });
    }
  };

  useEffect(() => {
    const fetchSubpools = async () => {
      const subpoolsRef = ref(db, 'subpools');
      try {
        const snapshot = await get(subpoolsRef);
        if (snapshot.exists()) {
          const subpoolsData = snapshot.val();
          const loadedPoolsOptions: PoolOption[] = Object.keys(subpoolsData).map((key) => {
            return {
              value: key, // Assuming the key is the subpool ID
              label: subpoolsData[key].name || 'Unnamed Pool' // Provide a default value if name is not available
            };
          });
          setPoolsOptions(loadedPoolsOptions);
        } else {
          console.log("No subpools available.");
          setPoolsOptions([]);
        }
      } catch (error) {
        console.error("Error fetching subpools: ", error);
      }
    };

    fetchSubpools();
  }, []); // Empty dependency array ensures this runs only once on mount


  return (
    <>
      <Navbar />
      <Header title="Poules" />
      <DefaultContainer>
        {message.text && (
          <p style={{ color: message.type === "error" ? 'red' : 'black', marginTop: '10px', textAlign: 'left' }}>
            {message.text}
            {message.link && <a href={message.link} style={{ color: 'blue', fontWeight: 'bold' }} target="_blank" rel="noopener noreferrer">{message.link}</a>}
          </p>
        )}
        {subpools.length > 0 && userId ? (
          <div className="md:w-1/2">
            <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">Subpools</h2>
            <div className="py-4 px-8 bg-white shadow-lg rounded-lg my-2">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Subpool
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Aantal leden
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {subpools.map((subpool) => (
                    <tr key={subpool.id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-m">
                        <Link to={`/subpool/${subpool.name}`} className="text-blue-600 hover:text-blue-800">
                          {subpool.name}
                        </Link>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {subpool.memberCount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p>Je hebt nog niet aangemeld bij subpools.</p>
        )}
        {userId ? (
          <div className="p-2 flex flex-col items-start w-full">
            <div className="flex flex-col items-start w-full max-w-md mt-6">
              <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">Aanmelden</h2>
              <Select
                options={poolsOptions}
                value={selectedPool}
                onChange={handleChange}
                placeholder="Selecteer een subpoule"
                className="w-full mb-4"
              />
              <input
                type="text"
                className="text-sm text-black border rounded-md p-2 w-full mb-4"
                value={joinCode}
                onChange={(e) => setJoinCode(e.target.value)}
                placeholder="Vul een code in"
              />
              <button
                className="bg-[#F9BF4B] text-black px-5 py-2 rounded-md font-medium hover:bg-[#f9bf4b]/90 focus:outline-none focus:ring-2 focus:ring-[#F9BF4B]/50 w-full mb-6"
                onClick={handleJoinSubpool}
              >
                Aanmelden
              </button>
            </div>
            {/* <div className="flex flex-col items-start w-full max-w-md">
              <h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">Nieuwe pool</h2>
              <input
                className="text-sm text-black border rounded-md p-2 w-full mb-4"
                type="text"
                value={subpoolName}
                onChange={(e) => setSubpoolName(e.target.value)}
                placeholder="Kies een naam voor de subpool"
              />
              <button
                className="bg-[#F9BF4B] text-black px-5 py-2 rounded-md font-medium hover:bg-[#f9bf4b]/90 focus:outline-none focus:ring-2 focus:ring-[#F9BF4B]/50 w-full"
                onClick={handleCreateSubpool}
              >
                Subpool aanmaken
              </button>
            </div> */}
          </div>
        ) : (
          <p>Login of registreer om deze functionaliteit te gebruiken.</p>
        )}
      </DefaultContainer>
    </>
  );
};

export default Pools;
