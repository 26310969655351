import { Fragment, useCallback } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { auth } from '../config/firebase';
import { useAuth } from "../context/AuthContext";
import { signOut } from "firebase/auth";
import Logo from '../assets/logo.svg';
import CupLogo from '../assets/logo.svg';
import React from 'react';

function isCurrent(route: string): boolean {
  return window.location.pathname === route;
}

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}

const Navbar = React.memo(() => {
  const user = useAuth()?.user;
  const displayName = useAuth()?.displayName;
  const navigate = useNavigate();

  const logout = useCallback(() => {
    signOut(auth).catch((error) => {
      console.error("Logout error:", error);
    });
  }, []);

  const navigation = [
    { name: 'Stand', href: '/' },
    { name: user ? 'Voorspellingen' : 'Wedstrijden', href: user ? `/user/${displayName}` : '/matches' },
    { name: 'Groepen', href: '/groups' },
    { name: 'Subpoules', href: '/pools' },
    { name: 'Regels', href: '/rules' },
  ];

  return (
    <Disclosure as="nav" className="bg-[#143cdb]">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-[#F9bF4B] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src={CupLogo}
                    alt="UEFA Euro 2024 Pool"
                  />
                  <a href="/" target="_self" rel="noopener noreferrer">
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src={Logo}
                      alt="UEFA Euro 2024 Pool"
                    />
                  </a>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          isCurrent(item.href) ? 'bg-[#F9BF4B] text-black' : 'text-gray-300 hover:bg-[#F9BF4B] hover:text-black',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={isCurrent(item.href) ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                {user ? (
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user.photoURL || 'https://robohash.org/6'}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/user/${displayName}`}
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm')}
                            >
                              Voorspellingen
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={logout}
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm w-full text-left')}
                            >
                              Uitloggen
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <Link className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium" to="/signin">Inloggen</Link>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href="#"
                  onClick={() => navigate(item.href)}
                  className={classNames(
                    isCurrent(item.href) ? 'bg-[#F9BF4B] text-black' : 'text-gray-300 hover:bg-[#F9BF4B] hover:text-black',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={isCurrent(item.href) ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
});

export default Navbar;
