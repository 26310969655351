import { createContext, useContext, useEffect, useState } from "react";
import { auth, db, FirebaseUser } from "../config/firebase";
import { ref, set, get, child, DatabaseReference } from "firebase/database";
import BouncingBall from "../components/BouncingBall";

interface AuthProviderProps {
  children?: JSX.Element | JSX.Element[];
}

interface AuthContextProps {
  user: FirebaseUser | null | undefined;
  displayName: string | null | undefined;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

export function useAuth(): AuthContextProps | null {
  return useContext(AuthContext);
}

function isFirebaseError(error: unknown): error is { code: string, message: string } {
  return typeof error === 'object' && error !== null && 'code' in error && 'message' in error;
}

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [displayName, setDisplayName] = useState('');
  const [authLoading, setAuthLoading] = useState<Boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      auth.onAuthStateChanged(async (u) => {
        setUser(u);
        if (u) {
          //console.log(`User ID: ${u.uid}`); // Debugging line
          const dbRef: DatabaseReference = ref(db);
          try {
            const snapshot = await get(child(dbRef, `users/${u.uid}`));
            const currentTimestamp = new Date().toISOString();
            if (!snapshot.exists()) {
              const displayName: string | null = u.displayName;
              //console.log(`displayName: ${displayName}`); // Log displayName
              if (displayName) {
                setDisplayName(displayName);
                await set(ref(db, `users/${u.uid}`), {
                  displayName: displayName,
                  photoURL: u.photoURL || 'https://robohash.org/6',
                  score: 0,
                  joined: currentTimestamp  // Add the timestamp here
                });
                console.log('User data saved successfully.');
              } else {
                console.error('User displayName is null or undefined.');
              }
            } else {
              setDisplayName(snapshot.val().displayName);
            }
          } catch (error) {
            if (isFirebaseError(error)) {
              console.error('Error fetching or saving user data:', error.message);
              if (error.code === 'PERMISSION_DENIED') {
                console.error('Permission denied. Check your Firebase Realtime Database rules.');
              }
            } else {
              console.error('Unexpected error', error);
            }
          } finally {
            setAuthLoading(false);
          }
        } else {
          setAuthLoading(false);
        }
      });
    };

    fetchData();
  }, []);

  if (authLoading) {
    return <BouncingBall />;
  }
  return (
    <AuthContext.Provider value={{ user, displayName }}>
      {children}
    </AuthContext.Provider>
  );
}
