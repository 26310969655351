import { useMatches } from '../context/MatchesContext';
import moment from 'moment';
import Navbar from "../components/Navbar";
import MatchItem from "../components/MatchItem";
import Header from "../components/Header";
import { MatchesContainer } from '../components/Containers';
import { Fragment } from 'react';

const Matches = () => {
  let matches = useMatches() || [];

  // Sort matches by date in ascending order
  matches = matches?.sort((a, b) => {
    return moment(a.date).diff(moment(b.date));
  });

  let prevDate = '';
  let matchDate = '';
  let round = '';
  let prevRound = '';

  return (
    <>
      <Navbar />
      <Header title="Wedstrijden" />
      <MatchesContainer>
        {matches?.map((match, index) => {
          round = match.round;
          matchDate = moment(match.date).format('dddd DD MMMM YYYY');
          if (round !== prevRound && matchDate !== prevDate) {
            prevRound = round;
            prevDate = matchDate;
            return (
              <Fragment key={`${match.id}-header`}>
                <div className="md:col-span-2 text-xl font-bold text-gray-900">{round}</div>
                <div className="md:col-span-2 text-xl">{matchDate}</div>
                <MatchItem key={match.id} {...match} />
              </Fragment>
            );
          }
          if (matchDate !== prevDate) {
            prevDate = matchDate;
            return (
              <Fragment key={`${match.id}-header`}>
                <div className="md:col-span-2 text-xl">{matchDate}</div>
                <MatchItem key={match.id} {...match} />
              </Fragment>
            );
          } else {
            return (
              <MatchItem key={match.id} {...match} />
            );
          }
        })}
      </MatchesContainer>
    </>
  );
}

export default Matches;