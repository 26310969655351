import { MatchProps } from "../interfaces";
import Moment from 'react-moment';
import 'moment-timezone';

const MatchItem = (match: MatchProps) => {
  const homeFlag = require('../assets/' + match.home + '.png');
  const awayFlag = require('../assets/' + match.away + '.png');

  return (
    <div className="py-4 px-8 bg-white shadow-lg rounded-lg my-2">
      <div className="md:flex md:justify-between">
        <div className="md:w-1/2">
          <table className="w-full border-separate [border-spacing:0.75rem]">
            <tbody>
              <tr>
                <td className="w-10"><img className="w-8 md:w-12" alt={match.home} src={homeFlag} /></td>
                <td className="text-sm md:text-xl">{match.homeName}</td>
                <td className="text-sm md:text-base text-right">
                  <>{match.homeScore! > -1 ? match.homeScore : <span className="text-gray-300">--</span>}</>
                </td>
              </tr>
              <tr>
                <td><img className="w-8 md:w-12" alt={match.away} src={awayFlag} /></td>
                <td className="text-sm md:text-xl">{match.awayName}</td>
                <td className="text-sm md:text-base text-right">
                  <>{match.awayScore! > -1 ? match.awayScore : <span className="text-gray-300">--</span>}</>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="md:w-1/2 md:text-right text-xs text-gray-700 mt-2 md:mt-0">
          {/* Always render group */}
          {match.group !== "N/A" && <> {match.group} ·</>}
          {match.round.toString() !== "Groepsfase" ? <> {match.round} ·</> : null}
          { <> {match.location} · </>}
          <Moment format="HH:mm">{match.date}</Moment>
        </div>
      </div>
    </div>
  )
}

export default MatchItem;