import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase'
import { signInWithRedirect, GoogleAuthProvider, signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import { useAuth } from "../context/AuthContext";
import CupLogo from '../assets/ekpoolpro.png';
import BouncingBall from '../components/BouncingBall';

const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  signInWithRedirect(auth, provider);
}

export default function SignIn() {
  const useauth = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [userLoading, setUserLoading] = useState<Boolean>(true);
  // Add state variables for messages
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const user = useauth?.user;
    const displayName = useauth?.displayName;
    if (user && displayName && !isError) {
      navigate(`/user/${displayName}`);
    } else {
      setUserLoading(false);
    }
  }, [useauth, isError, navigate]);

  // Function to handle user login with email and password
  const handleLogin = async () => {
    try {
      // Sign in with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        // Proceed with login if the email is verified
        navigate(`/user/${user.uid}`); // Navigate to user's page (or whatever your logic is)
      } else {
        // If the email is not verified, inform the user
        setMessage("Email is nog niet geverifieerd.\n Controleer je mailbox.");
        console.log(auth.currentUser?.emailVerified);
        setIsError(true);
        auth.signOut();
      }
    } catch (error) {
      // Handle login errors here
      if (error instanceof Error) {
        if (error.message.includes('auth/')) {
          const errorCode = error.message.split('/')[1].split(')')[0];
          setMessage(errorCode.replace(/-/g, ' ')); // Format error code
        } else {
          // For other types of errors, use a generic message
          setMessage("An error occurred, please try again");
        }
      } else {
        setMessage("An unknown error occurred");
      }
      setIsError(true);
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setMessage("Vul eerst je e-mailadres in.");
      setIsError(true);
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Controleer je email voor een resetlink.");
      setIsError(false);
    } catch (error) {
      if (error instanceof Error) {
        setMessage("Email formaat is onjuist.");
        setIsError(true);
      }
    }
  };

  if (userLoading) {
    return <BouncingBall />
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="flex justify-center w-300 bg-white p-10 rounded">
        <div className="grid grid-cols-1 gap-2">
          <div className="flex justify-center items-center"><img alt="logo" src={CupLogo} /></div>
          <div>{message && (
            <div style={{  whiteSpace: 'pre-line', color: 'red', marginTop: '5px' }} className={`message ${isError ? 'error-message' : 'success-message'}`}>
              {message}
            </div>
          )}</div>
          <button type="button" onClick={signInWithGoogle} className="min-w-full w-full text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
            <svg className="w-4 h-4 mr-2 -ml-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
            Login met Google
          </button>
          <label htmlFor="emailInput" style={{ display: 'block', marginBottom: '5px' }}>Login via email</label>
          <input
            type="email"
            id="emailInput"
            style={{
              padding: '10px 15px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              fontSize: '16px',
              outline: 'none',
              width: '100%', // Adjust width as needed
              boxSizing: 'border-box' // Ensures padding doesn't affect width
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            aria-label="Email"
            onFocus={(e) => e.target.style.borderColor = '#4285F4'}
            onBlur={(e) => e.target.style.borderColor = '#ccc'}
          />
          <input
            type="password"
            id="passwordInput"
            style={{
              padding: '10px 15px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              fontSize: '16px',
              outline: 'none',
              width: '100%',
              boxSizing: 'border-box'
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Wachtwoord"
            aria-label="Password"
          />
          <button className="min-w-full w-full text-white bg-[#ff6600] hover:bg-[#ff6600]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2" onClick={handleLogin}>
            Login
          </button>
          <button
            className="min-w-full w-full text-white bg-[#143cdb] hover:bg-[#ff6600]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2"
            onClick={handlePasswordReset}>
             Wachtwoord vergeten?
          </button>
          <button
            className="min-w-full w-full text-white bg-[#143cdb] hover:bg-[#ff6600]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2"
            onClick={() => navigate('/register')}>
            Nieuwe gebruiker
          </button>
        </div>
      </div>
    </div>
  );
}