import { useState, useEffect, useCallback } from 'react';
import { useAuth } from "../context/AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import CupLogo from '../assets/ekpoolpro.png';
import BouncingBall from '../components/BouncingBall';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { auth, db } from '../config/firebase'
import { ref, query, orderByChild, equalTo, get, set } from "firebase/database";
import moment from 'moment';

export default function Register() {
    // State for all the input fields
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setdisplayName] = useState("");

    const useauth = useAuth();
    const navigate = useNavigate();
    const [userLoading, setUserLoading] = useState<Boolean>(true);
    // Add state variables for messages
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false); // New state for success

    // Add state variables for form validation
    const [isFormValid, setIsFormValid] = useState(false);
    // State to track input field validation
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [displayNameError, setDisplayNameError] = useState(false);
    const [displayNameTaken, setDisplayNameTaken] = useState(false); // New state for displayName taken

    const [touched, setTouched] = useState({
        email: false,
        password: false,
        displayName: false,
    });

    const handleBlur = (field: any) => {
        setTouched({ ...touched, [field]: true });
    };

    const actionCodeSettings = {
        url: 'https://ekpool.pro/signin',
        handleCodeInApp: true,
        expiresIn: 60 * 60 * 24, // 24 hours
    };

    const validateForm = useCallback(() => {
        const isValidEmail = email === '' || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        setEmailError(!isValidEmail && (email !== '' || touched.email));

        const isValidPassword = password === '' || password.length >= 6;
        setPasswordError(!isValidPassword && (password !== '' || touched.password));

        const isValidDisplayName = displayName === '' || (displayName.length > 1 && /^[A-Za-z0-9.\-@#$!_ ]{1,30}$/.test(displayName));
        setDisplayNameError(!isValidDisplayName && (displayName !== '' || touched.displayName));

        return isValidEmail && isValidPassword && isValidDisplayName;
    }, [email, password, displayName, touched]);

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [validateForm]);

    useEffect(() => {
        const user = useauth?.user;
        const displayName = useauth?.displayName;
        if (user && displayName && !isError) {
            navigate(`/user/${displayName}`);
        } else {
            setUserLoading(false);
        }
    },
        [useauth, isError, navigate]);

    const handleRegister = async () => {
        try {
            setIsError(false);
            setDisplayNameTaken(false); // Reset displayName taken state
            const displayNameQuery = query(ref(db, 'users'), orderByChild('displayName'), equalTo(displayName.trimEnd()));
            const displayNameSnapshot = await get(displayNameQuery);
            if (displayNameSnapshot.exists()) {
                setMessage("Gebruikersnaam is al in gebruik");
                setIsError(true);
                setDisplayNameTaken(true); // Set displayName taken state
                return;
            }

            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            sendEmailVerification(userCredential.user, actionCodeSettings);
            setMessage("Registratie succesvol! \n \n");
            setIsError(false);
            setIsSuccess(true); // Set success state
            await updateProfile(userCredential.user, {
                displayName: displayName.replace(/(^\s+|\s+$)/g, '')
            });

            await set(ref(db, `users/${userCredential.user.uid}`), {
                displayName: displayName.trimEnd(),
                //email: email,
                joined: moment().toISOString(),
                score: 0,
                photoURL: userCredential.user.photoURL || 'https://robohash.org/6'
            });

            setdisplayName("");
            setEmail("");
            setPassword("");

            auth.signOut();

        } catch (error) {
            if (error instanceof Error) {
                if (error.message.includes('auth/')) {
                    const errorCode = error.message.split('/')[1].split(')')[0];
                    setMessage(errorCode.replace(/-/g, ' '));
                } else {
                    setMessage("Er ging iets mis");
                }
            } else {
                setMessage("Er ging iets mis");
            }
            setIsError(true);
            setIsSuccess(false); // Reset success state on error
        }
    };

    if (userLoading) {
        return <BouncingBall />
    }

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="flex justify-center w-300 bg-white p-10 rounded">
                <div className="grid grid-cols-1 gap-2">
                    <div className="flex justify-center items-center"><img alt="logo" src={CupLogo} /></div>
                    {isSuccess ? (
                        <div style={{ whiteSpace: 'pre-line', marginTop: '5px' }} className={`message success-message`}>
                            {message}
                            <p style={{ color: 'black' }}>Controleer (ongewenste) email om te bevestigen. <br /><br />
                                <Link to="/signin" className="text-blue-500">Inloggen</Link>
                            </p>
                        </div>
                    ) : (
                        <>
                            {message && isError && (
                                <div style={{ whiteSpace: 'pre-line', marginTop: '5px' }} className={`message error-message`}>
                                    {message}
                                </div>
                            )}
                            <label htmlFor="userInput" style={{ display: 'block', marginBottom: '5px' }}>Nieuwe gebruiker</label>
                            <input
                                type="input"
                                id="userNameInput"
                                className={`input-field ${displayNameError || displayNameTaken ? 'input-field-error' : 'input-field-valid'}`}
                                value={displayName}
                                onChange={(e) => setdisplayName(e.target.value)}
                                placeholder="Jan de Jong"
                                aria-label="Gebruikersnaam"
                                onBlur={() => handleBlur('displayName')}
                            />
                            {displayNameTaken && <p className="text-red-500 text-xs italic">Gebruikersnaam is al in gebruik</p>}
                            <input
                                type="email"
                                id="emailInput"
                                className={`input-field ${emailError ? 'input-field-error' : 'input-field-valid'}`}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                aria-label="Email"
                                onBlur={() => handleBlur('email')}
                            />
                            <input
                                type="password"
                                id="passwordInput"
                                className={`input-field ${passwordError ? 'input-field-error' : 'input-field-valid'}`}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Wachtwoord"
                                aria-label="Wachtwoord"
                                onBlur={() => handleBlur('password')}
                            />
                            <button
                                disabled={!isFormValid || password === '' || email === ''} // Disable button if form is invalid
                                style={{
                                    backgroundColor: !isFormValid || password === '' || email === '' ? '#ccc' : '#143cdb', // Grey background if button is disabled
                                    cursor: !isFormValid || password === '' || email === '' ? 'not-allowed' : 'pointer', // Change cursor if button is disabled
                                }}
                                className="min-w-full w-full text-white bg-[#143cdb] hover:bg-[#ff6600]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2"
                                onClick={handleRegister}>
                                Registreer
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
