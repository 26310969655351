import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from "../config/firebase";
import { ref, get, query, orderByChild, equalTo } from 'firebase/database';
import Navbar from "../components/Navbar";
import { DefaultContainer } from '../components/Containers';
import { UserProps } from '../interfaces';
import { getOrdinalSuffix } from '../utils';
import { useNavigate } from 'react-router-dom';

function SubpoolRankings() {
  const [users, setUsers] = useState<UserProps[]>([]);
  const [sortField, setSortField] = useState<'score' | 'joined'>('score');
  const { subpoolName } = useParams<{ subpoolName?: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (subpoolName) {
      // Query to find subpool by name
      const subpoolsRef = query(ref(db, 'subpools'), orderByChild('name'), equalTo(subpoolName));

      get(subpoolsRef).then((subpoolSnapshot) => {
        if (subpoolSnapshot.exists()) {
          const promises: any[] = [];

          subpoolSnapshot.forEach((subpool) => {
            const subpoolId = subpool.key; // This is the actual ID of the subpool
            const subpoolUsersRef = ref(db, `subpools/${subpoolId}/members`);

            const promise = get(subpoolUsersRef).then(userSnapshots => {
              if (userSnapshots.exists()) {
                const userIds = Object.keys(userSnapshots.val());
                const userPromises = userIds.map(userId => get(ref(db, `users/${userId}`)));
                
                return Promise.all(userPromises).then(usersData => {
                  return usersData.map((snap, index) => {
                    if (snap.exists()) {
                      return { id: userIds[index], ...snap.val() };
                    }
                    return null;
                  }).filter(user => user); // Filter out null values if any
                });
              }
              return [];
            });

            promises.push(promise);
          });

          Promise.all(promises).then(usersArrays => {
            const usersList = usersArrays.flat(); // Flatten the array of arrays
            sortAndRankUsers(usersList);
          });
        }
      });
    } else {
      console.log("Subpool name is undefined");
    }
  }, [subpoolName, sortField]);

  const sortAndRankUsers = (usersList: UserProps[]) => {
    if (sortField === 'score') {
      usersList.sort((a, b) => b.score - a.score);
  
      let ranking = 1;
      let prevScore = usersList[0]?.score ?? 0;
      let tieCount = 1;
  
      usersList.forEach((user, index) => {
        if (user.score !== prevScore) {
          ranking = index + 1;
          tieCount = 1;
        } else {
          tieCount++;
        }
        user.ranking = ranking;
        prevScore = user.score;
      });
  
    } else if (sortField === 'joined') {
      usersList.sort((a, b) => new Date(a.joined).getTime() - new Date(b.joined).getTime());
      usersList.forEach((item, index) => {
        item.ranking = index + 1; // Assign ranking based on sorted order
        item.joinOrder = index + 1; // Assign join order based on sorted order
      });
    }
    setUsers(usersList);
  };

  const handleSortChange = (field: 'score' | 'joined') => {
    setSortField(field);
    sortAndRankUsers([...users]); // Sort and rank users on sort field change
  };

  return (
    <>
      <Navbar />
      <DefaultContainer>
        <h2 className="text-2xl my-4 font-bold text-gray-900">Stand - {subpoolName}</h2>
        <div className="py-4 px-8 bg-white shadow-lg rounded-lg my-2">
          <table className="w-full border-separate [border-spacing:0.25rem]">
            <thead>
              <tr>
                <th className="text-left text-gray-500">Nr</th>
                <th className="w-14 h-14"></th>
                <th className="text-gray-500 text-left">Naam</th>
                <th className="text-right text-gray-500 cursor-pointer" onClick={() => handleSortChange('score')}>Punten</th>
                <th className="text-right text-gray-500 cursor-pointer" onClick={() => handleSortChange('joined')}>#</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id} onClick={() => navigate(`/user/${user.displayName}`)} className="hover:cursor-pointer">
                  <td className="text-left text-gray-500">{user.ranking}<sup>{getOrdinalSuffix(user.ranking)}</sup></td>
                  <td className={`w-14 h-14 ranking-${user.ranking}`}><img className="h-12 w-12 rounded-full" alt={user.displayName} src={user.photoURL} /></td>
                  <td className="text-xl">{user.displayName}</td>
                  <td className="text-right text-xl">{user.score}</td>
                  <td className="text-right text-xl">{user.joinOrder}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </DefaultContainer>
    </>
  );
}

export default SubpoolRankings;
