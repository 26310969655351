import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from "./config/firebase";
import { ref, onValue, get, DatabaseReference } from 'firebase/database';
import Select, { SingleValue, ActionMeta } from 'react-select';
import './App.css';
import { MatchProps, UserProps } from './interfaces';
import Navbar from "./components/Navbar";
import { DefaultContainer } from './components/Containers';
import MatchItem from './components/MatchItem';
import { useMatches } from './context/MatchesContext';
import moment from 'moment';
import { getOrdinalSuffix } from './utils';
import 'moment/locale/nl';
import { useAuth } from './context/AuthContext';
import ReactPaginate from 'react-paginate';

moment.locale('nl');

interface OptionType {
  value: string;
  label: string;
}

const ITEMS_PER_PAGE = 50; // Number of items per page

function App() {
  const [users, setUsers] = useState<UserProps[]>([]);
  const [subpools, setSubpools] = useState<OptionType[]>([{ value: 'all', label: 'Overall' }]);
  const [selectedSubpool, setSelectedSubpool] = useState<OptionType>({ value: 'all', label: 'Overall' });
  const [currentPage, setCurrentPage] = useState(0); // Current page state
  const [currentUserRank, setCurrentUserRank] = useState<number | null>(null); // Current user's rank
  const navigate = useNavigate();
  const currentDate = moment(new Date()).format('YYYYMMDD');
  const matches = useMatches();
  const authUser = useAuth()?.user;
  const userId = authUser?.uid;

  useEffect(() => {
    const fetchSubpools = async () => {
      if (userId) {
        const userSubpoolsRef = ref(db, `users/${userId}/subpools`);
        const userSnapshot = await get(userSubpoolsRef);
        if (userSnapshot.exists()) {
          const userSubpools = Object.keys(userSnapshot.val());
          const subpoolDetails = await Promise.all(
            userSubpools.map(subpoolId => get(ref(db, `subpools/${subpoolId}`)))
          );

          const loadedOptions = subpoolDetails.map(snapshot => {
            return {
              value: snapshot.key ?? "unknown_id", // Ensure `value` is a non-null string
              label: snapshot.exists() && snapshot.val().name ? snapshot.val().name : 'Unnamed Pool'
            };
          });

          if (loadedOptions.length > 0) {
            setSelectedSubpool(loadedOptions[0]); // Set the first subpool as the default selected
            loadedOptions.unshift({ value: 'all', label: 'Overall' });
          }
          setSubpools(loadedOptions);
        } else {
          setOnlyOverallOption();
        }
      } else {
        setOnlyOverallOption();
      }
    };

    const setOnlyOverallOption = () => {
      const defaultOption = [{ value: 'all', label: 'Overall' }];
      setSubpools(defaultOption);
      setSelectedSubpool(defaultOption[0]);
    };

    fetchSubpools();
  }, [userId]);

  const sortAndSetUsers = useCallback((usersList: UserProps[]) => {
    usersList.sort((a, b) => b.score - a.score);

    let ranking = 1;
    let prevScore = usersList[0]?.score ?? 0;
    let tieCount = 1;

    usersList.forEach((user, index) => {
      if (user.score !== prevScore) {
        ranking = index + 1;
        tieCount = 1;
      } else {
        tieCount++;
      }
      user.ranking = ranking;
      prevScore = user.score;
    });

    setUsers(usersList);

    // Determine the current user's rank
    const currentUser = usersList.find(user => user.id === userId);
    if (currentUser) {
      setCurrentUserRank(currentUser.ranking);
    }
  }, [userId]);

  useEffect(() => {
    const fetchUsers = async () => {
      let usersRef: DatabaseReference;
      if (selectedSubpool.value === 'all') {
        usersRef = ref(db, 'users');
      } else {
        usersRef = ref(db, `subpools/${selectedSubpool.value}/members`);
      }

      onValue(usersRef, async (snapshot) => {
        const promises: any[] = [];
        snapshot.forEach(childSnapshot => {
          const userRef: DatabaseReference = ref(db, `users/${childSnapshot.key}`);
          promises.push(get(userRef));
        });

        const userSnapshots = await Promise.all(promises);
        const usersList: UserProps[] = userSnapshots.map(snap => {
          return snap.exists() ? { id: snap.key, ...snap.val() } : null;
        }).filter(user => user !== null) as UserProps[];

        sortAndSetUsers(usersList);
      });
    };

    fetchUsers();
  }, [selectedSubpool, sortAndSetUsers]);

  const handleChange = (selectedOption: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    if (selectedOption) {
      setSelectedSubpool(selectedOption);
      setCurrentPage(0); // Reset to first page on subpool change
    } else {
      setSelectedSubpool({ value: 'all', label: 'Overall' });
    }
  };

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * ITEMS_PER_PAGE;
  const currentPageData = users.slice(offset, offset + ITEMS_PER_PAGE);
  const pageCount = Math.ceil(users.length / ITEMS_PER_PAGE);

  const todaysMatches = (matches || []).filter((match: MatchProps) => {
    const matchDate = moment(match.date).format('YYYYMMDD');
    return matchDate === currentDate;
  });

  return (
    <>
      <Navbar />
      <DefaultContainer>
        <div className="md:w-1/2">
          {subpools.length > 1 && (
            <Select
              options={subpools}
              value={selectedSubpool}
              onChange={handleChange}
              placeholder="Selecteer een pool"
              className="mb-4"
            />
          )}
        </div>
        <div>
          {todaysMatches.length > 0 && (
            <h2 className="col-span-2 text-2xl my-4 font-bold text-gray-900">Vandaag</h2>
          )}
          {todaysMatches.map((match: MatchProps) => (
            <MatchItem key={match.id} {...match} />
          ))}
        </div>
        <div>
          <h2 className="text-2xl my-4 font-bold text-gray-900">Stand - {selectedSubpool ? selectedSubpool.label : 'Overall'}</h2>
          {currentUserRank !== null && (
            <div className="my-4 p-4 bg-blue-100 rounded-lg">
              <h3 className="text-l">
                Jouw positie: <span className="font-bold">{currentUserRank}<sup>{getOrdinalSuffix(currentUserRank)}</sup></span>
              </h3>
            </div>
          )}
          <div className="py-4 px-8 bg-white shadow-lg rounded-lg my-2">
            <table className="w-full border-separate [border-spacing:0.75rem]">
              <tbody>
                {currentPageData.map((user) => (
                  <tr key={user.id} onClick={() => navigate(`/user/${user.displayName}`)} className={`hover:cursor-pointer ${currentPage !== 0 && user.id === userId ? 'bg-yellow-100' : ''}`}>
                    <td className="text-right text-gray-500">{user.ranking}<sup>{getOrdinalSuffix(user.ranking)}</sup></td>
                    <td className={`w-14 h-14 ranking-${user.ranking}`}><img className="h-12 w-12 rounded-full" alt={user.displayName} src={user.photoURL} /></td>
                    <td className="text-xl">{user.displayName}</td>
                    <td className="text-right text-xl">{user.score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {pageCount > 1 && (
            <div className="pagination-container">
              <ReactPaginate
                previousLabel={'vorige'}
                nextLabel={'volgende'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
            )}
          </div>
        </div>
      </DefaultContainer>
    </>
  );
}

export default App;
