import Navbar from "../components/Navbar";
import Header from "../components/Header";
import { DefaultContainer } from '../components/Containers';
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import QRImage from '../assets/qr-tok.png';



const Rules = () => {
  const homeFlag = require('../assets/GER.png');
  const awayFlag = require('../assets/SCO.png');
  const [isHovered, setIsHovered] = useState(false);
  const [timeLeft, setTimeLeft] = useState('');

  // Setting up the target date in Berlin time
  const targetDate = moment.tz("2024-06-14 20:59:59", "Europe/Berlin").toDate();

  useEffect(() => {
    // Update the countdown once every second
    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      // Update time left
      setTimeLeft(`${days} dagen, ${hours} uur, ${minutes} minuten, ${seconds} seconden`);

      // If the countdown is over, clear the interval
      if (difference < 0) {
        clearInterval(interval);
        setTimeLeft('Toernooi is al gestart!');
      }
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, [targetDate]);


  return (
    <>
      <Navbar />
      <Header title="Regels" />
      <DefaultContainer>
        <h2 className="text-2xl font-bold text-gray-900 pt-8">Deadline</h2>
        <p className="pt-2">Vul alles in voor de deadline over: <br></br><span className="font-bold">{timeLeft}</span>.</p>
        <h2 className="text-2xl font-bold text-gray-900 pt-8">Deelname STEVO</h2>
        <ul className="list-disc list-inside pt-2">
          <li>
            <a href="./register" className="text-blue-500 underline">Aanmelden</a>
            {' '}met voor- en achternaam of via{' '}
            <a href="./signin" className="text-blue-500 underline">Google</a>.
          </li>
          <li>
            Meld je aan voor de {' '}
            <a href="https://ekpool.pro/pools?name=STEVO&code=KX4L4GK9HYE" className="text-blue-500 underline">
              STEVO subpool.
            </a> Link aanklikken is genoeg.
          </li>
          <li>Inleg is € 5,00 (inleggeld is prijzengeld).</li>
          <div
            className="hover-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <li>Overmaken via {' '}
              <a href="https://betaalverzoek.rabobank.nl/betaalverzoek/?id=UNhvWJQDRo2ZXk5VRAKuhw" target="_blank" rel="noreferrer" className="text-blue-500 underline">
                betaalverzoek
              </a> of naar NL22RABO0115714936 op naam van: Jan Telgenhof Oude Koehorst.</li>
            {isHovered && (
              <div className="qr-code">
                <img src={QRImage} alt="QR Code" />
              </div>
            )}
          </div>
        </ul>
        <h2 className="text-2xl font-bold text-gray-900 pt-8">Eindstand goed</h2>
        <p className="pt-2">Eindstand na 90 minuten goed voorspeld geeft <span className="font-bold">12 punten</span>.</p>
        <h2 className="text-2xl font-bold text-gray-900 pt-8">Toto goed voorspeld</h2>
        <div className="pt-2">Toto (winnaar of gelijkspel) goed <span className="font-bold">5 punten</span>.<br />
          Doelpunten verschil: <b>5 punten - aantal doelpunten verschil</b>
          <p className="pt-8 text-xs text-gray-500">Bijvoorbeeld:</p>
          <table>
            <tbody>
              <tr>
                <td></td>
                <td className="text-center"><img className="w-8 md:w-12" alt="home" src={homeFlag} /></td>
                <td className="text-gray-400 text-center p-2">VS</td>
                <td className="text-center"><img className="w-8 md:w-12" alt="away" src={awayFlag} /></td>
              </tr>
              <tr>
                <td className="bold text-right pr-4">Voorspelling:</td>
                <td className="text-center">2</td>
                <td className="text-center text-gray-300">–</td>
                <td className="text-center">1</td>
              </tr>
              <tr>
                <td className="bold text-right pr-4">Uitslag:</td>
                <td className="text-center">1</td>
                <td className="text-center text-gray-300">–</td>
                <td className="text-center">0</td>
              </tr>
              <tr>
                <td className="bold text-right pr-4">Formule:</td>
                <td className="text-center" colSpan={3}>5 - (2 - 1) - (1 - 0) = 3</td>
              </tr>
              <tr>
                <td className="bold text-right pr-4">Je krijgt:</td>
                <td className="text-center font" colSpan={3}><b>8 punten</b> (5+3)</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2 className="text-2xl font-bold text-gray-900 pt-8">Finales</h2>
        <p className="pt-2">Achtste finalist goed: <span className="font-bold">10 punten</span>.</p>
        <p className="pt-2">Kwart finalist goed: <span className="font-bold">15 punten</span>.</p>
        <p className="pt-2">Halve finalist goed: <span className="font-bold">20 punten</span>.</p>
        <p className="pt-2">Finalist goed: <span className="font-bold">25 punten</span>.</p>
        <p className="pt-2">Toernooiwinnaar goed: <span className="font-bold">30 punten</span>.</p>
        <p className="pt-2">Topscorer goed: <span className="font-bold">30 punten</span>.</p><br></br>
      </DefaultContainer>
    </>
  );
}

export default Rules;