import { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { db } from '../config/firebase';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import { DefaultContainer } from "../components/Containers";


export interface ITeam {
  code: string;
  group: string;
  name: string;
}


const Groups = () => {

  const [teams, setTeams] = useState<Record<string, ITeam>>({});

  useEffect(() => {
    const teamsRef = ref(db, 'teams');
    const unsubscribe = onValue(teamsRef, (snapshot) => {
      if (snapshot.exists()) {
        setTeams(snapshot.val());
      } else {
        console.log("Er ging iets mis.");
      }
    });

    return () => unsubscribe();  // Clean up the subscription
  }, []);

  // Organize teams by group
  const groupedTeams = Object.values(teams).reduce<Record<string, ITeam[]>>((acc, team) => {
    acc[team.group] = acc[team.group] || [];
    acc[team.group].push(team);
    return acc;
  }, {});

  // Convert groups into an array and sort, then remove the last group
  const groups = Object.entries(groupedTeams).sort((a, b) => a[0].localeCompare(b[0]));
  groups.pop();  // Removes the last group

  return (
    <>
      <Navbar />
      <Header title="Groepen" />
      <DefaultContainer>
        <div className="md:w-1/2">
          {groups.map(([group, teams]) => (
            <><h2 className="text-2xl font-bold text-gray-900 mt-6 mb-4">{group}</h2><div className="py-4 px-8 bg-white shadow-lg rounded-lg my-2">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase">

                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Land
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {teams.map((team) => (
                    <tr key={team.code}>
                      <td style={{ width: "50px" }}>
                        <img src={`https://img.uefa.com/imgml/flags/50x50/${team.code}.png`} alt={team.name} style={{ width: "30px", display: "block", margin: "auto" }} />
                      </td>
                      <td>{team.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div></>
          ))}
        </div>
      </DefaultContainer>
    </>
  );
};

export default Groups;
