import { useParams, Navigate } from 'react-router-dom';
import { useState, useEffect, Fragment } from 'react';
import { useMatches } from '../context/MatchesContext';
import { db } from '../config/firebase';
import { get, ref, orderByChild, equalTo, query, onValue, set } from 'firebase/database';
import { UserProps, PredictionProps, Scores } from '../interfaces';
import Navbar from '../components/Navbar';
import PredictionItem from '../components/PredictionItem';
import moment from 'moment';
import { PredictionsContainer } from '../components/Containers';
import { getOrdinalSuffix } from '../utils';
import Select, { SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useAuth } from "../context/AuthContext";

export default function UserProfile() {
  let matches = useMatches() || [];
  // Sort matches by date in ascending order
  matches = matches?.sort((a, b) => {
    return moment(a.date).diff(moment(b.date));
  });

  let { id } = useParams();
  const [loading, setLoading] = useState<Boolean>(true);
  const [user, setUser] = useState<UserProps | null>(null);
  const [userPredictions, setUserPredictions] = useState<PredictionProps[] | null>(null);
  const [selectedWinner, setSelectedWinner] = useState<string>('');
  const [topScorer, setTopScorer] = useState<string>('');

  const styles = {
    menu: (base: any) => ({
      ...base,
      width: "max-content",
      minWidth: "100%"
    })
  };

  const players = [
    { "value": "Harry Kane", "label": "Harry Kane" },
    { "value": "Kylian Mbappe", "label": "Kylian Mbappe" },
    { "value": "Cristiano Ronaldo", "label": "Cristiano Ronaldo" },
    { "value": "Romelu Lukaku", "label": "Romelu Lukaku" },
    { "value": "Alvaro Morata", "label": "Alvaro Morata" },
    { "value": "Rasmus Hojlund", "label": "Rasmus Hojlund" },
    { "value": "Antoine Griezmann", "label": "Antoine Griezmann" },
    { "value": "Bukayo Saka", "label": "Bukayo Saka" },
    { "value": "Phil Foden", "label": "Phil Foden" },
    { "value": "Matias Goncalo Ramos", "label": "Matias Goncalo Ramos" },
    { "value": "Ciro Immobile", "label": "Ciro Immobile" },
    { "value": "Niclas Fullkrug", "label": "Niclas Fullkrug" },
    { "value": "Maximillian Beier", "label": "Maximillian Beier" },
    { "value": "Ilkay Gundogan", "label": "Ilkay Gundogan" },
    { "value": "Rafael Leao", "label": "Rafael Leao" },
    { "value": "Patrik Schick", "label": "Patrik Schick" },
    { "value": "Lois Openda", "label": "Lois Openda" },
    { "value": "Kevin De Bruyne", "label": "Kevin De Bruyne" },
    { "value": "Gianluca Scamacca", "label": "Gianluca Scamacca" },
    { "value": "Diogo Jota", "label": "Diogo Jota" },
    { "value": "Sequeira Joao Felix", "label": "Sequeira Joao Felix" },
    { "value": "Marcus Thuram", "label": "Marcus Thuram" },
    { "value": "Steven Bergwijn", "label": "Steven Bergwijn" },
    { "value": "Jude Bellingham", "label": "Jude Bellingham" },
    { "value": "Olivier Giroud", "label": "Olivier Giroud" },
    { "value": "Cody Gakpo", "label": "Cody Gakpo" },
    { "value": "Donyell Malen", "label": "Donyell Malen" },
    { "value": "Robert Lewandowski", "label": "Robert Lewandowski" },
    { "value": "Thomas Muller", "label": "Thomas Muller" },
    { "value": "Memphis Depay", "label": "Memphis Depay" },
    { "value": "Georginio Wijnaldum", "label": "Georginio Wijnaldum" },
    { "value": "Kai Havertz", "label": "Kai Havertz" },
    { "value": "Jamal Musiala", "label": "Jamal Musiala" },
    { "value": "Aleksandar Mitrovic", "label": "Aleksandar Mitrovic" },
    { "value": "Zeki Amdouni", "label": "Zeki Amdouni" }
  ];

  const sortedPlayers = players.sort((a, b) => a.label.localeCompare(b.label));

  const authUser = useAuth()?.user;
  const userID = authUser?.uid;

  useEffect(() => {
    let idVal = id ?? '';
    const q = query(ref(db, 'users'), orderByChild('displayName'), equalTo(idVal));

    get(q).then(snapshot => {
      if (snapshot.exists()) {
        const k = Object.keys(snapshot.val())[0];
        const u = { ...snapshot.val()[k], uid: k };
        setUser(u);

        // Get the winner from the predictions
        const winnerRef = ref(db, `predictions/${k}/winner`);
        onValue(winnerRef, (winnerSnapshot) => {
          if (winnerSnapshot.exists()) {
            setSelectedWinner(winnerSnapshot.val());
          }
        });

        // Fetch the top scorer
        const topScorerRef = ref(db, `predictions/${k}/topScorer`);
        onValue(topScorerRef, topScorerSnapshot => {
          if (topScorerSnapshot.exists()) {
            setTopScorer(topScorerSnapshot.val());
          }
        });

        const predictionsRef = ref(db, `predictions/${k}`);
        onValue(predictionsRef, snapshotPredictions => {
          const predictionsList = new Map<string, PredictionProps>();
          matches.forEach(match => {
            predictionsList.set(match.id, { ...match, homePrediction: -1, awayPrediction: -1, points: -1, uid: k, homeNamePrediction: '', homeTeamPrediction: '', awayNamePrediction: '', awayTeamPrediction: '' });
          });
          snapshotPredictions.forEach(childSnapshot => {
            const childId = childSnapshot.key;
            if (childId) {
              const item = predictionsList.get(childId);
              if (item) {
                predictionsList.set(childId, { ...item, ...childSnapshot.val() });
              }
            }
          });
          setUserPredictions(Array.from(predictionsList.values()));
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [matches, id]);

  if (!loading && !user) {
    return <Navigate to='/' replace={true} />;
  }

  const isCurrentUser = user?.uid === userID && Date.now() < 1718391600000;;

  const handleWinnerChange = async (selectedOption: SingleValue<{ value: string; label: string }>) => {
    if (selectedOption && user) {
      const validPattern = /^\p{L}+$/u; // Use the Unicode flag 'u'
      if (!validPattern.test(selectedOption.value)) {
        console.error("Invalid winner data");
        return;
      }

      setSelectedWinner(selectedOption.value);

      const userId = user.uid;
      if (userId) {
        const winnerRef = ref(db, `predictions/${userId}/winner`);
        try {
          await set(winnerRef, selectedOption.value);
          console.log("Winner updated successfully");
        } catch (error) {
          console.error("Error updating winner:", error);
        }
      }
    } else {
      setSelectedWinner('');
    }
  };

  const handleTopScorerChange = async (selectedOption: SingleValue<{ value: string; label: string }> | null) => {
    if (selectedOption) {
      setTopScorer(selectedOption.value);
      const userId = user?.uid;
      if (userId) {
        const topScorerRef = ref(db, `predictions/${userId}/topScorer`);
        try {
          await set(topScorerRef, selectedOption.value);
          console.log("Top scorer prediction updated successfully");
        } catch (error) {
          console.error("Error updating top scorer prediction:", error);
        }
      }
    } else {
      setTopScorer('');
      const userId = user?.uid;
      if (userId) {
        const topScorerRef = ref(db, `predictions/${userId}/topScorer`);
        try {
          await set(topScorerRef, '');  // Optionally clear the top scorer in the database
          console.log("Top scorer prediction cleared");
        } catch (error) {
          console.error("Error clearing top scorer prediction:", error);
        }
      }
    }
  };

  const isValidTopScorer = (inputValue: string): boolean => {
    const validPattern = /^[a-zA-Z\u00C0-\u00FF '.,-]+$/;  // Allows letters, common special characters in names, spaces
    if (!inputValue || inputValue.length < 2 || inputValue.length > 50) {
      return false;  // Validates length
    }
    return validPattern.test(inputValue);  // Validates characters
  };

  const handleCreateTopScorer = (inputValue: string): void => {
    if (isValidTopScorer(inputValue)) {
      handleTopScorerChange({ value: inputValue, label: inputValue });
    } else {
      alert("Ongeldige naam");
    }
  };

  const specificMatchPrediction = userPredictions?.find(pred => pred.id === '2036211'); // Adjust to your specific match ID

  let matchRound = '';
  let previousMatchRound = '';
  let prevDate = '';
  let matchDate = '';

  return (
    <>
      <Navbar />
      <header className="bg-white shadow md:block px-2 py-4">
        {user && (
          <table className="max-w-7xl mx-auto py-20 sm:px-6 lg:px-8 w-full">
            <tr>
              <td className="text-right text-gray-500"><sup>{user.ranking! > 0 ? user.ranking + getOrdinalSuffix(user.ranking) : ""}</sup></td>
              <td className={`w-14 h-14 ranking-${user.ranking}`}><img className="h-12 w-12 rounded-full" alt={user.userName} src={user.photoURL} /></td>
              <td className="text-3xl font-bold text-gray-900">{user.displayName}</td>
              <td className="text-right text-xl">{user.score} punten</td>
            </tr>
          </table>
        )}
      </header>
      <PredictionsContainer>
        {userPredictions && userPredictions.map((match, index) => {
          matchRound = match.round;
          matchDate = moment(match.date).format('dddd DD MMMM YYYY');
          const roundScore = user && user.scores ? user.scores[matchRound as keyof Scores] : 0;

          return (
            <Fragment key={match.id}>
              {matchRound !== previousMatchRound && (
                <div className="md:col-span-2 text-xl mt-4 bt-4 font-bold text-gray-900">
                  {`${previousMatchRound = matchRound} - ${roundScore} punten`}  {/* Update prevRound for the next iteration */}
                </div>
              )}
              {matchDate !== prevDate && (
                <div className="md:col-span-2 text-l">
                  {prevDate = matchDate}  {/* Update prevDate for the next iteration */}
                </div>
              )}
              <PredictionItem {...match} />
              {match.id === '2036211' && (
                <div className="final-winner-selection">
                  <div className="md:col-span-2 text-xl mt-4 bt-4 font-bold text-gray-900">
                  <h2 className="final-winner-heading">{"Winnaar - " + (user?.scores?.scoreWinner ?? 0) + " punten"}</h2>
                  </div>
                  <div className="final-match-details">
                    {/* Render match details for the final here */}
                  </div>
                  <table className="full-width">
                    <tbody>
                      <tr>
                        {isCurrentUser ? (
                          <td className="text-xl">
                            <Select
                              value={{ value: selectedWinner, label: selectedWinner || "Winnaar" }}
                              styles={styles}
                              isDisabled={!isCurrentUser}
                              onChange={handleWinnerChange}
                              options={[
                                { value: specificMatchPrediction?.homeNamePrediction || "", label: specificMatchPrediction?.homeNamePrediction || "W49" },
                                { value: specificMatchPrediction?.awayNamePrediction || '', label: specificMatchPrediction?.awayNamePrediction || "W50" }
                              ]}
                            />
                          </td>
                        ) : (
                          <td className="text-xl"><span>{selectedWinner || ""}</span></td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </Fragment>
          );
        })}
        <div className="top-scorer-selection">
          <table className="full-width">
            <tbody>
              <tr>
                {isCurrentUser ? (
                  <td className="text-xl">

                    <CreatableSelect
                      value={{ value: topScorer, label: topScorer || "Select Top Scorer" }}
                      onChange={handleTopScorerChange}
                      options={sortedPlayers}
                      placeholder="Selecteer of Typ"
                      isClearable
                      onCreateOption={handleCreateTopScorer}
                      isDisabled={!isCurrentUser}
                    />
                  </td>
                ) : (<td className="text-xl"><div className="md:col-span-2 text-xl mt-4 bt-4 font-bold text-gray-900"><h2 className="top-scorer-title">{" Topscorer - " + (user?.scores?.scoreTopScorer ?? 0) + " punten"}</h2></div><span>{topScorer || "Jammer joh, voorspellingen van concurrenten zijn pas zichtbaar na de toernooistart."}</span></td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </PredictionsContainer>
    </>
  );
}
